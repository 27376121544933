<template>
  <div
    ref="$root"
    :class="{ 'is-playing': isPlaying }"
    :style="{ aspectRatio: `${ratio.width} / ${ratio.height}` }"
    class="video"
  >
    <transition name="fade">
      <button
        v-if="!isPlaying && !playerOptions.autoplay && provider !== 'html5'"
        class="video__cta"
        :aria-label="t('video-play')"
        @click="plyr?.play()"
      >
        <GCtaRounded
          :text="t('video-play')"
          :repeat="2"
          :offset="25"
          :foreground="foreground"
          :background="background"
          :icon="content.icon ?? 'play'"
          name="read-case"
          size="large"
        />
      </button>
    </transition>
    <div
      v-if="content.id"
      :id="`a${id}`"
      data-plyr-provider="vimeo"
      :data-plyr-embed-id="content.id"
      class="video__player"
    ></div>
    <video
      v-if="content.src"
      :id="`a${id}`"
      ref="$video"
      autoplay
      playsinline
      muted
      loop
      class="video__player"
    >
      <source v-if="content.sets" :src="videoUrl.mp4" type="video/mp4" />
      {{ t('video-support') }}
    </video>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import {
  computed,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  shallowRef,
} from 'vue'
import { v4 as uuidv4 } from 'uuid' // Vlitjs requires a unique id for each video
import ScrollTrigger from 'gsap/ScrollTrigger'

import { getValue } from '@/modules/colors'
import { useUiStore } from '@/stores/ui'
import { useChromeStore } from '@/stores/chrome'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  // https://github.com/yoriiis/vlitejs#Options
  options: {
    type: Object as PropType<Plyr.Options>,
    default: () => ({}),
    required: false,
  },
  content: {
    type: Object,
    required: true,
  },
})

const ui = useUiStore()
const { t } = useChromeStore()

const $video = ref<HTMLVideoElement | null>(null)
const $root = ref(null)
const isPlaying = ref(false)
const foreground = ref('')
const background = ref('')
const width = ref(0)
const height = ref(0)
const id = ref(uuidv4())
let triggerInstance: ScrollTrigger

const videoUrl = computed(() => {
  const resolution = ui.isSmallLarger ? '1440' : '720'

  return {
    mp4: props.content.sets.mp4[resolution],
  }
})

const getGCD = (width: number, height: number) => {
  let x = Math.abs(width)
  let y = Math.abs(height)

  while (y) {
    const t = y
    y = x % y
    x = t
  }

  return x
}

const ratio = computed(() => {
  const w = width.value || props.content.width
  const h = height.value || props.content.height
  if (!w || !h) {
    return { width: 16, height: 9 }
  }

  const gcd = getGCD(w, h)

  return {
    width: w / gcd,
    height: h / gcd,
  }
})

const play = () => {
  if (!plyr.value) {
    return
  }

  plyr.value?.play()
  isPlaying.value = true
}

const stop = async () => {
  if (!plyr.value) {
    return
  }

  plyr.value?.pause()
  isPlaying.value = false
}

const provider = computed(() => (props.content.id ? 'vimeo' : 'html5'))
const isLocal = provider.value === 'html5'
const playerOptions = ref<Plyr.Options>({
  controls: isLocal ? [] : ['play', 'progress', 'mute', 'volume', 'fullscreen'],
  hideControls: isLocal,
  loop: { active: isLocal },
  muted: isLocal,
  autoplay: isLocal,

  ...props.options,
})

const playerSelector = computed(() => `#a${id.value}`)

const lum = (hex: string) => {
  const r = parseInt(hex.substr(1, 2), 16)
  const g = parseInt(hex.substr(3, 2), 16)
  const b = parseInt(hex.substr(5, 2), 16)

  // eslint-disable-next-line no-mixed-operators
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

const setPlayerControlsColor = () => {
  if (!plyr.value) {
    return
  }
  const foreground = getValue('foreground', document.documentElement)
  const background = getValue('background', document.documentElement)

  const fLum = lum(foreground.replace('#', ''))
  const bLum = lum(background.replace('#', ''))

  if (fLum > bLum) {
    plyr.value?.elements.container?.style.setProperty(
      '--plyr-color-main',
      foreground
    )
    plyr.value?.elements.container?.style.setProperty(
      '--plyr-video-control-color',
      foreground
    )
  } else {
    plyr.value?.elements.container?.style.setProperty(
      '--plyr-color-main',
      background
    )
    plyr.value?.elements.container?.style.setProperty(
      '--plyr-video-control-color',
      background
    )
  }
}

const initTrigger = () => {
  triggerInstance && triggerInstance.kill()
  triggerInstance = new ScrollTrigger({
    trigger: $root.value,
    onEnter: () => provider.value === 'html5' && play(),
    onEnterBack: () => provider.value === 'html5' && play(),
    onLeave: () => stop(),
    onLeaveBack: () => stop(),
    start: 'center bottom',
  })
}

const plyr = shallowRef<Plyr>()
const initPlayer = async () => {
  import('plyr/dist/plyr.css')

  plyr.value = new (await (
    await import('plyr')
  ).default)(playerSelector.value, playerOptions.value)

  plyr.value.on('ready', () => {
    if (!plyr.value) {
      return
    }

    initTrigger()
    initResizeObserver()
    setPlayerControlsColor()

    const ratios = plyr.value?.ratio?.split(':')
    if (ratios) {
      ratio.value.width = Number(ratios[0])
      ratio.value.height = Number(ratios[1])
    }
    plyr.value.muted = plyr.value.autoplay
  })

  plyr.value.on('play', () => {
    isPlaying.value = true
  })

  plyr.value.on('pause', () => {
    isPlaying.value = false
  })

  plyr.value.on('ended', () => (isPlaying.value = false))
}

let resizeObserver: ResizeObserver
const initResizeObserver = () => {
  resizeObserver = new ResizeObserver(() => {
    triggerInstance && triggerInstance.refresh()
  })

  resizeObserver.observe(document.body)
}

onMounted(async () => {
  if (
    (provider.value === 'html5' && !props.content.src) ||
    (provider.value === 'vimeo' && !props.content.id)
  ) {
    return
  }

  await initPlayer()

  foreground.value = getValue('foreground', document.documentElement)
  background.value = getValue('background', document.documentElement)
})

onBeforeUnmount(() => {
  plyr.value?.destroy()
  resizeObserver?.disconnect()
  triggerInstance?.kill()
})
</script>

<style lang="scss" scoped>
.video {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: border-radius 0.5s;

  :deep(.plyr) {
    --plyr-video-controls-background: transparent;

    height: 100%;
  }
}

.video__cta {
  @include get-all-space;

  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: 0;
}

.video__player {
  @include image-fit;
}
</style>
